.createProductContainer{
    /* background-color: rgb(0, 0, 22) !important; */
    padding: 0rem 5rem;
    
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Remove spinner in Chrome, Safari, and newer versions of Edge */
  margin: 0; /* Optional: to remove spacing */
}

input[type="number"] {
  -moz-appearance: textfield; /* Remove spinner in Firefox */
}
