
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Playfair:ital,opsz,wght@0,5..1200,300..900;1,5..1200,300..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400..700;1,400..700&display=swap');
@font-face {
  font-family: 'Heading';
  src: url('../public/fonts/headinfont.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(244, 240, 240); */
  /* background-color: #15151e; */
  min-height: 100%;
}
.group:hover .group-hover\:opacity-100 {
  opacity: 1 !important;
}
.gruppo{
  font-family: "Cuprum", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
}
.Head{
  font-family: 'Heading', sans-serif;
}
.SubHead{
  font-family: 'Playfair Display', sans-serif;
}
.Para{
  font-family: Inter, sans-serif;
}

.gold-border {
  padding: 10px 20px; /* Adjust padding */
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Text color */
  background: linear-gradient(to right, #ffdf76, #cca834); /* Gradient for the border */
  border: 3px solid transparent; /* Make the actual border transparent */
  border-radius: 8px; /* Rounded corners */
  background-origin: border-box; /* Outer background applied to the border box */
  background-clip: padding-box, border-box; /* Gradient clipped to border box */
}

.gradient-bg-btn {
  padding: 10px 30px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 30px; /* Fully rounded corners */
  background: linear-gradient(to right, #ff4e50, #f9d423); /* Pink to orange gradient */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Remove spinner in Chrome, Safari, and newer versions of Edge */
  margin: 0; /* Optional: to remove spacing */
}

input[type="number"] {
  -moz-appearance: textfield; /* Remove spinner in Firefox */
}




@tailwind base;
@tailwind components;
@tailwind utilities;