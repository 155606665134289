.productCard {
  background-color: #ffffff; /* White background */
  border-radius: 0.5rem; /* Rounded corners */
  overflow: hidden; /* Prevents child elements from overflowing */
}

.textPart {
  padding: 1rem; /* More padding for a modern look */
}

.textPart p {
  margin: 0; /* Remove default margins */
}

.productCard:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
}
